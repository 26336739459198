<!-- 提现 -->
<template>
  <div class="withdrawal">
    <header class="withdrawalheader">
      <img class="moneyLog" src="../../assets/money.jpg" alt="">
    </header>
    <main class="withdrawalMain">
      <p class="withdrawalText">我的余额</p>
      <p class="withdrawalNumber">
        ¥ {{ usrInfoObj.ua_balance }}
      </p>

      <div class="flex1">
        <p class="moneyInput">
          <van-field label-class='moneyInputText' v-model="money" label="¥" label-width='10' format-trigger="onBlur"
            :formatter="formatter" placeholder="提现金额" :error-message="errorMessage" />
        </p>
        <span class="moneyInputButton" @click="allWithdrawal">全部提现</span>
      </div>

      <p class="submit">
        <van-button :disabled='money == "" || money == undefined || money == 0' type="primary"
          @click="submit">立即提现</van-button>
      </p>
    </main>

  </div>
</template>

<script>
import G_time from "../../tools/time";
export default {
  data() {
    return {
      usrInfoObj: {},
      money: "",
      errorMessage: "",
    };
  },

  components: {},

  created() {
    this.getUserinfo();
  },

  methods: {
    getUserinfo() {
      this.$axios
        .get("/api/user/getInfo")
        .then((res) => {
          res.data.u_regist_time = G_time.ts2Date(res.data.u_regist_time);
          this.usrInfoObj = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    submit() {


      if (!this.usrInfoObj.u_ali_account) {
        this.$toast.fail("请先前往个人中心设置支付宝账号");
        return false;
      }
      if (this.money !== undefined) {
        this.$dialog
          .confirm({
            title: "支付宝账号确认",
            message:
              "本次提现将提现至支付宝账号:" +
              this.usrInfoObj.u_ali_account +
              "姓名:" +
              this.usrInfoObj.u_ali_realname,
          })
          .then(() => {
            // on confirm
            // for (let i = 0; i < 1; i++) {
            this.$axios
              .post("/api/account/cash", {
                co_price: this.money,
              })
              .then((res) => {
                console.log(res);
                if (res.code == "000") {
                  this.$toast.success("提交成功,请耐心等待!");
                  this.$router.push({
                    name: "personalCenter",
                  });
                } else if (res.code == "162") {
                  this.$dialog.confirm({
                    title: '限额提醒',
                    message: '超过单日提现额度，可前往实名认证提高额度',
                    confirmButtonText: '前往实名',
                    cancelButtonText: '取消'
                  })
                    .then(() => {
                      this.$router.push({ name: 'realName' })
                    })
                    .catch(() => {

                    });
                } else {
                  this.$toast.fail(res.msg);
                }
              })
              .catch((err) => {
                console.log(err);
                this.$toast.fail("提交失败");
              });
            // }
          })
          .catch(() => { });
      }
    },
    formatter(value) {
      var testMoney = /^[0-9]*(\.[0-9]{1,2})?$/;
      console.log(testMoney.test(value));
      if (!testMoney.test(value)) {
        this.errorMessage = "输入金额格式错误";
        this.money = "";
      } else {
        if (value > Number(this.usrInfoObj.ua_balance)) {
          this.errorMessage = "输入金额超过余额";
          this.money = "";
        } else {
          this.errorMessage = "";
          return value;
        }
      }
      console.log("提现", this.money);
    },
    allWithdrawal() {
      this.money = this.usrInfoObj.ua_balance;
    },
  },
};
</script>
<style lang='scss'>
.withdrawal {
  .withdrawalheader {
    height: 150px;
    // border-bottom: 1px solid #000;

    text-align: center;

    .moneyLog {
      width: 60px;
      margin-top: 80px;
    }
  }

  .withdrawalMain {
    p {
      padding: 0px;
      margin: 0px;
      width: 100%;
      text-align: center;
      margin-bottom: 20px;
    }

    .withdrawalText {
      font-size: 15px;
    }

    .withdrawalNumber {
      font-size: 25px;
      font-weight: 600;
    }

    .moneyInput {
      margin-top: 30px;
      border-bottom: 1px solid rgb(112, 112, 112);
      width: 240px;
      display: flex;
      align-items: center;
    }

    .moneyInputText {
      font-size: 20px;
      color: #000;
      font-weight: 700;
    }

    .moneyInputButton {
      display: inline-block;
      // border: 1px solid #000;
      height: 60px;
      width: 60px;
      line-height: 60px;
    }

    .submit {
      margin-top: 30px;
    }
  }

  .flex1 {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>